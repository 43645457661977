import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardTitle, CardBody, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

//External libraries
import { ThreeCircles } from 'react-loader-spinner'
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";

//Image imports
import locationMarker from "../../assets/images/icons/location_marker.png";

//Firebase functionality
import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';

const LoadingContainer = () => {
    <ThreeCircles
    visible={true}
    height="100"
    width="100"
    color="#4192C3"
    ariaLabel="three-circles-loading"
    wrapperStyle={{style:"margin: 0px auto"}}
    wrapperClass="justify-content-center"
    />
};

const ExploreMap = (props) => {
    
    document.title = "Explore Map | Fish-Trak - Partner's Dashboard";
    
    //Session data references
    const userData = JSON.parse(localStorage.getItem("userData"));
    const programData = JSON.parse(localStorage.getItem("programData"));
    
    //Data queries
    const [initialLoad, setInitialLoad] = useState(true);
    const [allCatches, setAllCatches] = useState([]);
    const [fetchedData, setFetchedData] = useState(false);
    
    const fetchAllCatches = async () => {
        
      const programRef = firebase.firestore().collection('programs').doc(programData.id); 
          
     firebase
     .firestore()
     .collection('catches')
     .where('tag_program', '==', programRef)
     .orderBy('created_at', 'desc')
     .onSnapshot(
          querySnapshot => {
              const newCatchLedger = []
              querySnapshot.forEach(doc => {
                  const catchItem = doc.data();
                  const catchId = doc.id;
                  catchItem.id = catchId;
                  catchItem.angler = catchItem.user_name;
                  catchItem.user_link = 'user/details/' + catchItem.user.id; 
                  
                  const catchDate = catchItem && catchItem.catch_date && catchItem.catch_date.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  catchItem.date = catchDate;
                  catchItem.link = 'catch/details/' + catchId;  
                  catchItem.tag_link = 'tag/details/' + catchItem.tag.id; 
                  newCatchLedger.push(catchItem);
              });
              setAllCatches(newCatchLedger);
              setFetchedData(true);
          },
          error => {
              console.log(error);
          }
      );
      
    };
    
    useEffect(() => {
         
        if(initialLoad) {
           setInitialLoad(false);
           fetchAllCatches();
         }
        
    });
    
    return (
        <React.Fragment>

                <div className="page-content" style={{ margin: 0, padding: 0 }}>

                                        <div id="gmaps-explore" className="gmaps-explore">

                                            <Map
                                                google={props.google}
                                                zoom={8}
                                                // initialCenter={{ lat: 34.134117, lng: -118.321495 }}
                                                streetViewControl={false}
                                                fullscreenControl={false}
                                                mapTypeControl={false}
                                                zoomControlOptions = {{
                                                    position: props.google.maps.ControlPosition.RIGHT_TOP,
                                                }}
                                            >
                                                
                                                {(allCatches.length > 0) && allCatches.map((catchItem, index) => ( 
                                                <Marker 
                                                    name={catchItem.name}
                                                    position={{ lat: catchItem.location.latitude, lng: catchItem.location.longitude }} 
                                                    icon={{
                                                      url: locationMarker,
                                                      anchor: new props.google.maps.Point(32,32),
                                                      scaledSize: new props.google.maps.Size(64,64)
                                                    }}
                                                />
                                                ))}
                                            </Map>

                                        </div>

                </div>
        </React.Fragment>
    );
}

export default (
    GoogleApiWrapper({
        apiKey: "AIzaSyCcnZ45Qluty82SrlZkkjbFZFKFflYbiZQ",
        LoadingContainer: LoadingContainer,
        v: "3",
    })(ExploreMap)
)




