import React, { useRef, useState, useEffect } from 'react'
import { Button, Card, CardBody, CardTitle, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, Row, ModalHeader } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import List from 'list.js';
import Flatpickr from "react-flatpickr";
import { ThreeCircles } from 'react-loader-spinner'

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import {serverTimestamp} from 'firebase/firestore';

const UsedTags = () => {
    
    const [initialLoad, setInitialLoad] = useState(true);
    const [usedTagsList, setUsedTagsList] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [allTagPrograms, setAllTagPrograms] = useState([]);
    const [allUsersList, setAllUsersList] = useState([]);
    const [allSpecies, setAllSpecies] = useState([]);
    const [fetchedData, setFetchedData] = useState(false);
    
    //Action buttons
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateTagId, setUpdateTagId] = useState('');
    const [updateTagIndex, setUpdateTagIndex] = useState(null);
    const [deleteTagId, setDeleteTagId] = useState('');
    const [deleteTagIndex, setDeleteTagIndex] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    
    const [restoreTagId, setRestoreTagId] = useState('');
    const [restoreTagIndex, setRestoreTagIndex] = useState(null);
    const [isRestoringUsed, setIsRestoringUsed] = useState(false);
    
    //Session data references
    const userData = JSON.parse(localStorage.getItem("userData"));
    const programData = JSON.parse(localStorage.getItem("programData"));
    
    document.title = "Fish Tags | Fish-Trak - Partner's Dashboard";

    const [modal_list, setmodal_list] = useState(false);
    function tog_list() {
        setmodal_list(!modal_list);
    }
    
    const [editModal, setEditModal] = useState(false);
    function toggleEditModal(tagId, tagIndex) {
        
        if(editModal) {
            setUpdateTagId('');
            setUpdateTagIndex(null);
        }
        else {
            setUpdateTagId(tagId);
            setUpdateTagIndex(tagIndex);
        }
        
        setEditModal(!editModal);
    }
    
    const [deleteModal, setDeleteModal] = useState(false);
    function toggleDeleteModal(tagId, tagIndex) {
        
        if(deleteModal) {
            setDeleteTagId('');
            setDeleteTagIndex(null);
        }
        else {
            setDeleteTagId(tagId);
            setDeleteTagIndex(tagIndex);
        }
        
        setDeleteModal(!deleteModal);
        
    }
    
    const [restoreUsedModal, setRestoreUsedModal] = useState(false);
    function toggleRestoreUsedModal(tagId, tagIndex) {
        
        if(restoreUsedModal) {
            setRestoreTagId('');
            setRestoreTagIndex(null);
        }
        else {
            setRestoreTagId(tagId);
            setRestoreTagIndex(tagIndex);
        }
        
        setRestoreUsedModal(!restoreUsedModal);
        
    }
    
    const deleteTag = () => {
        
        setIsDeleting(true);
        
        const usersRef = firebase.firestore().collection('users');
        const tagRef = firebase.firestore().collection('tags').doc(usedTagsList[deleteTagIndex].id);
        const catchesRef = firebase.firestore().collection('catches');
        
        //Get all catches using the same tag
        catchesRef
         .where('tag', '==', tagRef)
         .onSnapshot(
              querySnapshot => {
                  querySnapshot.forEach(doc => {
                      const catchItem = doc.data();
                      
                      //For each catch decrement user catch/recatch count
                      const userData = {
                        updated_at: serverTimestamp(),
                      };
                      
                      if(catchItem.recatch) {                        
                        if(!catchItem.recatch_count || catchItem.recatch_count == 0 || catchItem.recatch_count == 1 || catchItem.recatch_count == '' || catchItem.recatch_count == null) {
                            userData['recatch_count'] = 0; 
                          }
                          else {
                              userData['recatch_count'] = firebase.firestore.FieldValue.increment(-1);
                          }
                      }  
                      else {
                          if(!catchItem.catch_count || catchItem.catch_count == 0 || catchItem.catch_count == 1 || catchItem.catch_count == '' || catchItem.catch_count == null) {
                            userData['catch_count'] = 0; 
                          }
                          else {
                              userData['catch_count'] = firebase.firestore.FieldValue.increment(-1);
                          }
                      }
                      
                      usersRef
                      .doc(catchItem.user.id)
                      .update(userData)
                      .then((userResponse) => {
                          
                          //Delete the catch or recatch
                          catchesRef
                            .doc(doc.id)
                            .update({
                                updated_at: serverTimestamp(),
                                deleted: true
                            })
                            .then(() => {
                                
                                console.log('Deleted tag');
                               
                            })
                            .catch((error) => {
                              setIsDeleting(false);
                              alert('Could not delete catch entry. Please try again.');
                            });
                          
                        
                      })
                      .catch((error) => {
                        setIsDeleting(false);
                        alert('Could not update user status. Please try again.');
                      }); 
                      
                  });
                  
                  tagRef
                    .update({
                        updated_at: serverTimestamp(),
                        deleted: true
                    })
                    .then(() => {
                        
                        fetchUsedFishTags();
                        console.log('The tag has been deleted successfully!');
                        setDeleteTagId('');
                        setDeleteTagIndex(null);
                       
                    })
                    .catch((error) => {
                      setIsDeleting(false);
                      alert('Could not delete fish tag entry. Please try again.');
                    });
                  
              },
              error => {
                  console.log(error);
              }
          );   
          
    }
    
    const restoreUsedTag = () => {
        
        setIsRestoringUsed(true);
        
        const usersRef = firebase.firestore().collection('users');
        const tagRef = firebase.firestore().collection('tags').doc(usedTagsList[restoreTagIndex].id);
        const catchesRef = firebase.firestore().collection('catches');
        
        //Get all catches using the same tag
        catchesRef
         .where('tag', '==', tagRef)
         .onSnapshot(
              querySnapshot => {
                  querySnapshot.forEach(doc => {
                      const catchItem = doc.data();
                      
                      //For each catch decrement user catch/recatch count
                      const userData = {
                        updated_at: serverTimestamp(),
                      };
                      
                      if(catchItem.recatch) {                        
                        userData['recatch_count'] = firebase.firestore.FieldValue.increment(1);
                      }  
                      else {
                        userData['catch_count'] = firebase.firestore.FieldValue.increment(1);
                      }
                      
                      usersRef
                      .doc(catchItem.user.id)
                      .update(userData)
                      .then((userResponse) => {
                          
                          //Delete the catch or recatch
                          catchesRef
                            .doc(doc.id)
                            .update({
                                updated_at: serverTimestamp(),
                                deleted: false
                            })
                            .then(() => {
                                
                                console.log('Restored tag');
                               
                            })
                            .catch((error) => {
                              setIsRestoringUsed(false);
                              alert('Could not restore catch entry. Please try again.');
                            });
                          
                        
                      })
                      .catch((error) => {
                        setIsRestoringUsed(false);
                        alert('Could not update user status. Please try again.');
                      }); 
                      
                  });
                  
                  tagRef
                    .update({
                        updated_at: serverTimestamp(),
                        deleted: false
                    })
                    .then(() => {
                        
                        fetchUsedFishTags();
                        console.log('The tag has been restored successfully!');
                        setRestoreTagId('');
                        setRestoreTagIndex(null);
                       
                    })
                    .catch((error) => {
                      setIsRestoringUsed(false);
                      alert('Could not restore fish tag entry. Please try again.');
                    });
                  
              },
              error => {
                  console.log(error);
              }
          );   
          
    }
    
    const submitUpdateForm = async (event) => {
                
        setIsUpdating(true);
        event.preventDefault();
        
        const programId = event.target.tag_program.value;
        const programIndex = event.target.tag_program.selectedIndex - 1;
        const userId = event.target.first_angler.value;
        const tagRef = firebase.firestore().collection('tags');
                 
        //Create array of catch data
        const tagData = {
          updated_at: serverTimestamp(),
        };
        
        if(event.target.tag_number.value != usedTagsList[updateTagIndex].tag) {
            tagData['tag_number'] = event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, '');
        }
        if(programId != usedTagsList[updateTagIndex].tag_program.id) {
            tagData['tag_program'] = firebase.firestore().collection('programs').doc(programId);
            tagData['program_image'] = allTagPrograms[programIndex].image;
            tagData['program_name'] = allTagPrograms[programIndex].name;
        }
        if(userId != usedTagsList[updateTagIndex].first_angler.id) {
            tagData['first_angler'] = firebase.firestore().collection('users').doc(userId);
        }
               
        //Update tag in firestore
        tagRef
            .doc(usedTagsList[updateTagIndex].id)
            .update(tagData)
            .then((tagResponse) => {
              alert('The tag has been updated successfully!');
              fetchUsedFishTags(); 
            })
            .catch((error) => {
              setIsUpdating(false);
              alert('Could not update tag entry. Please try again.');
            });  
    };
    
    const createList = () => {
         
          const tagsListOptions = {
              valueNames: ['tag', 'angler', 'species', 'created', 'updated', 'catch_count', 'status'],
              pagination: true,
              indexAsync: true,
              searchColumns: ['tag', 'angler', 'species', 'program'],
              page: 10,
          };     
         
         new List('used-tags-list', tagsListOptions);
    };
    
    const createNewFishTag = async (event) => {
        
        setIsUploading(true);
        event.preventDefault();
        
        const programIndex = event.target.tag_program.value;
        const userIndex = event.target.first_user.value;
        const tagRef = firebase.firestore().collection('tags');
                
      //Create array of catch data
        const tagData = {
          created_at: serverTimestamp(),
          updated_at: serverTimestamp(),
          tag_number: event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, ''),
          catch_count: 0,
          used: false,
          deleted: false,
          program_image: allTagPrograms[programIndex].image,
          program_name: allTagPrograms[programIndex].name,
          tag_program: firebase.firestore().collection('programs').doc(allTagPrograms[programIndex].id),
          first_angler: firebase.firestore().collection('users').doc(allUsersList[userIndex].id),
          angler_name: allUsersList[userIndex].name
        };
                
        //Create new tag in firestore
        tagRef
            .add(tagData)
            .then((tagResponse) => {
              alert('The new fish tag has been created successfully!');
              fetchUsedFishTags(); 
            })
            .catch((error) => {
              setIsUploading(false);
              alert('Could not create fish tag entry. Please try again.');
            });  
    };
    
    const fetchTagPrograms = async () => {
          
     firebase
     .firestore()
     .collection('programs')
     .orderBy('created_at', 'desc')
     .onSnapshot(
          querySnapshot => {
              const newProgramsList = []
              querySnapshot.forEach(doc => {
                  const programItem = doc.data();
                  programItem.id = doc.id;
                  
                  programItem.name = programItem.program_name;
                  programItem.region = programItem.program_region;
                  programItem.country = programItem.country_code;
                  programItem.type = programItem.program_type;
                  programItem.length = programItem.tag_length;
                  programItem.image = programItem.image_url;
                  
                  const createdAt = programItem && programItem.created_at && programItem.created_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                    programItem.created = createdAt;
                    const updatedAt = programItem && programItem.updated_at && programItem.updated_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                    programItem.updated = updatedAt;
                  
                  
                  newProgramsList.push(programItem);
              });
              setAllTagPrograms(newProgramsList);
          },
          error => {
              console.log(error);
          }
      )
    };
    
    const fetchUsedFishTags = () => {
        
    const programRef = firebase.firestore().collection('programs').doc(programData.id); 
          
     firebase
     .firestore()
     .collection('tags')
     .where('tag_program', '==', programRef)
     .where('used', '==', true)
     .orderBy('created_at', 'desc')
     .onSnapshot(
          querySnapshot => {
              const newTagsList = []
              querySnapshot.forEach(doc => {
                  const tagItem = doc.data();
                  const tagId = doc.id;
                  tagItem.id = tagId;
                  
                  tagItem.tag = '#' + tagItem.tag_number;
                  tagItem.program = tagItem.program_name;
                  const createdAt = tagItem && tagItem.created_at && tagItem.created_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  tagItem.created = createdAt;
                  const updatedAt = tagItem && tagItem.updated_at && tagItem.updated_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  tagItem.updated = updatedAt;
                  tagItem.link = 'tag/details/' + tagId;  
                  tagItem.userId = tagItem.first_angler.id;  
                  tagItem.user_link = 'user/details/' + tagItem.first_angler.id;  
                  tagItem.speciesId = tagItem.fish_species.id;  
                  tagItem.species_link = 'species/details/' + tagItem.fish_species.id;  
                  tagItem.program_link = 'program/details/' + tagItem.tag_program.id;   
                  
                  newTagsList.push(tagItem);
              });
              setUsedTagsList(newTagsList);
              setIsUploading(false);
              setmodal_list(false);
              setIsDeleting(false);
              setDeleteModal(false);
              setFetchedData(true);
              setIsDeleting(false);
              setEditModal(false);
              setIsUpdating(false);
              setRestoreUsedModal(false);
              setIsRestoringUsed(false);
              
          },
          error => {
              console.log(error);
          }
      )
    };
    
    const fetchAllUsers = async () => {
           
      firebase
      .firestore()
      .collection('users')
      .orderBy('created_at', 'desc')
      .onSnapshot(
           querySnapshot => {
               const newUsersList = []
               querySnapshot.forEach(doc => {
                   const userItem = doc.data();
                   userItem.id = doc.id;
                   
                   userItem.name = userItem.first_name + ' ' + userItem.last_name;
                   userItem.email = userItem.email_address;
                   userItem.port = userItem.home_port;
                   userItem.count = userItem.catch_count;
                   
                   const createdAt = userItem && userItem.created_at && userItem.created_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                     userItem.created = createdAt;
                     const updatedAt = userItem && userItem.updated_at && userItem.updated_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                     userItem.updated = updatedAt;
                   
                   if(userItem.active == true) {
                     userItem.status = 'Active';
                   }
                   else {
                     userItem.status = 'Disabled';
                   }        
                   
                   newUsersList.push(userItem);
               });
               setAllUsersList(newUsersList);
               
           },
           error => {
               console.log(error);
           }
       )
     };
     
     function findNameById(userId) {
         
        const userObject = allUsersList && allUsersList.find((user) => user.id === userId);
        
        if(userObject && userObject.name) {
              return userObject.name;
          }
          else {
              return "No User Name";
          }
    }
     
     const fetchAllSpecies = async () => {
            
       firebase
       .firestore()
       .collection('species')
       .orderBy('name', 'asc')
       .onSnapshot(
            querySnapshot => {
                const newAllSpecies = []
                querySnapshot.forEach(doc => {
                    const speciesItem = doc.data();
                    speciesItem.id = doc.id;
                    speciesItem.species_name = speciesItem.name;
                    newAllSpecies.push(speciesItem);
                });
                setAllSpecies(newAllSpecies);
            },
            error => {
                console.log(error);
            }
        )
      };
      
      function findSpeciesById(speciesId){
          
          const speciesObject = allSpecies && allSpecies.find((species) => species.id === speciesId);
          
          if(speciesObject && speciesObject.name) {
              return speciesObject.name
          }
          else {
              return "No Species";
          }
          
      }

    useEffect(() => {

         
        if(initialLoad) {
           setInitialLoad(false);
           fetchAllSpecies();
           fetchAllUsers();
           fetchUsedFishTags();
           fetchTagPrograms();
         }
         
         if(fetchedData && usedTagsList && (usedTagsList.length > 0)) {
            setFetchedData(false);
            createList();
          }  
         
        
    });
    


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <CardTitle>My Fish Tags</CardTitle>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Used Tags</h4>
                                </CardHeader>

                                <CardBody>
                                    <div id="used-tags-list">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-lg">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="search" className="fuzzy-search form-control" placeholder="Search Tags..." />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                        {!isUpdating && !isDeleting &&
                                            <table className="table align-middle table-nowrap" id="tags-table">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>View</th>
                                                        <th className="sort" data-sort="tag">Fish Tag Number</th>
                                                        <th className="sort" data-sort="angler">First Angler</th>
                                                        <th className="sort" data-sort="species">Fish Species</th>
                                                        <th className="sort" data-sort="created" data-default-order='desc'>Created Date</th>
                                                        <th className="sort" data-sort="updated" data-default-order='desc'>Updated Date</th>
                                                        <th className="sort" data-sort="catch_count">Catches</th>
                                                        <th className="sort" data-sort="status">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                
                                                    {(usedTagsList.length > 0) && usedTagsList.map((tag, index) => ( 
                                                        <tr key={index}>
                                                            <th scope="row">
                                                                <a className="btn btn-sm btn-success edit-item-btn" href={tag.link}><i className="mdi mdi-eye-outline"></i></a>
                                                            </th>
                                                            <td className="tag">{tag.tag}</td>
                                                            <td><a href={tag.user_link} className="angler fw-medium link-primary">
                                                                {findNameById(tag.userId)}
                                                            </a></td>
                                                            <td><a href={tag.species_link} className="species fw-medium link-primary">
                                                                {findSpeciesById(tag.speciesId)}
                                                            </a></td>
                                                            <td className="created">{tag.created}</td>
                                                            <td className="updated">{tag.updated}</td>
                                                            <td><span className="catch_count badge badge-soft-success text-uppercase">{tag.catch_count}</span></td>
                                                            
                                                            <td className="used">
                                                                <span className="completed badge badge-soft-success text-uppercase">Used</span>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                
                                                </tbody>
                                            </table>}
                                            
                                            {usedTagsList.length == 0 &&
                                            <div className="noresult">
                                                <div className="text-center">
                                                    <h5 className="mt-2">No Fish Tags Found!</h5>
                                                    <p className="text-muted mb-0">We could not find any fish tags.</p>
                                                </div>
                                            </div>
                                            }
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            <div className="pagination-wrap hstack gap-2">
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    

                    
                </Container>
            </div>

            {/* Edit Modal */}
            <Modal isOpen={editModal} toggle={() => { toggleEditModal(); }} centered >
                <ModalHeader className="bg-light p-3" id="editModal" toggle={() => { toggleEditModal(); }}> Edit Tag Program </ModalHeader>
                
                {!isUpdating && usedTagsList[updateTagIndex] &&
                <form className="tablelist-form" onSubmit={submitUpdateForm}>
                    <ModalBody>
                    
                        <div className="mb-3">
                            <label htmlFor="tagnumber-field" className="form-label">Tag Number*</label>
                            <input type="text" id="tagnumber-field" className="form-control" placeholder="#ABCDE1234567890" maxLength={15} required name="tag_number" defaultValue={usedTagsList[updateTagIndex].tag}/>
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="program-field" className="form-label">Tagging Program*</label>
                            <select className="form-control" data-trigger id="program-field" name="tag_program" required defaultValue={usedTagsList[updateTagIndex].tag_program.id}>
                                <option value="">Select Tag Program</option>
                                {allTagPrograms.map(({ name, id }, index) => <option value={id} key={index}>{name}</option>)}
                            </select>
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="program-field" className="form-label">Assigned User*</label>
                            <select className="form-control" data-trigger required id="program-field" name="first_angler" defaultValue={usedTagsList[updateTagIndex].first_angler.id}>
                                <option value="">Select User Email</option>
                                {allUsersList.map(({ email, id }, index) => <option value={id} >{email}</option>)}
                            </select>
                        </div>
            
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => toggleEditModal()}>Cancel</button>
                            <button type="submit" className="btn btn-success" id="add-btn">Update</button>
                        </div>
                    </ModalFooter>
                </form>
                }
                
                <ModalBody>
                    <ThreeCircles
                      visible={isUpdating}
                      height="100"
                      width="100"
                      color="#4192C3"
                      ariaLabel="three-circles-loading"
                      wrapperStyle={{style:"margin: 0px auto"}}
                      wrapperClass="justify-content-center"
                      />
                </ModalBody>
            </Modal>

            {/* Add Modal */}
            <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Add Fish Tag </ModalHeader>
                
                {!isUploading &&
                <form className="tablelist-form" onSubmit={createNewFishTag}>
                    <ModalBody>
                        <div className="mb-3">
                            <label htmlFor="tagnumber-field" className="form-label">Tag Number*</label>
                            <input type="text" id="tagnumber-field" className="form-control" placeholder="#ABCDE1234567890" maxLength={15} required name="tag_number"/>
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="program-field" className="form-label">Tagging Program*</label>
                            <select className="form-control" data-trigger id="program-field" name="tag_program" required>
                                <option value="">Select Tag Program</option>
                                {allTagPrograms.map(({ name }, index) => <option value={index} >{name}</option>)}
                            </select>
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="program-field" className="form-label">Assigned User*</label>
                            <select className="form-control" data-trigger  id="program-field" name="first_user" >
                                <option value="">Select User Email</option>
                                {allUsersList.map(({ email }, index) => <option value={index} >{email}</option>)}
                            </select>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Cancel</button>
                            <button type="submit" className="btn btn-success" id="add-btn">Add Tag</button>
                        </div>
                    </ModalFooter>
                </form>
                }
                
                
                <ModalBody>
                    <ThreeCircles
                      visible={isUploading}
                      height="100"
                      width="100"
                      color="#4192C3"
                      ariaLabel="three-circles-loading"
                      wrapperStyle={{style:"margin: 0px auto;"}}
                      wrapperClass="justify-content-center"
                      />
                </ModalBody>
            </Modal>
            
            {/* Remove Modal */}
            <Modal isOpen={deleteModal} toggle={() => { toggleDeleteModal(); }} className="modal zoomIn" id="deleteTagModal" centered >
            
                {!isDeleting &&
                <ModalBody>
                    <div className="mt-2 text-center">
                                <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                    <h4>Delete Used Fish Tag </h4><br/>
                                    <h5 className="text-muted mx-4 mb-0">This will delete the tag along with connected catches or recatches!!!</h5><br/>
                                    <p className="text-muted mx-4 mb-0">Are you sure you want to delete this tag?</p>
                                </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => toggleDeleteModal()}>Cancel</button>
                        <button type="button" className="btn w-sm btn-danger" id="delete-record" onClick={deleteTag}>Delete</button>
                    </div>
                </ModalBody>
                }
                    
                    
                    <ModalBody>
                        <ThreeCircles
                          visible={isDeleting}
                          height="100"
                          width="100"
                          color="#ff3d60"
                          ariaLabel="three-circles-loading"
                          wrapperStyle={{style:"margin: 0px auto"}}
                          wrapperClass="justify-content-center"
                          />
                    </ModalBody>
            </Modal>
            
            {/* Remove Modal */}
              <Modal isOpen={restoreUsedModal} toggle={() => { toggleRestoreUsedModal(); }} className="modal zoomIn" id="restoreUsedModal" centered >
              
                  {!isRestoringUsed &&
                  <ModalBody>
                      <div className="mt-2 text-center">
                          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                              <h4>Restore Used Fish Tag</h4>
                              <p className="text-muted mx-4 mb-0">This will restore the tag along with connected catches or recatches!!!</p>
                          </div>
                      </div>
                      <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                          <button type="button" className="btn w-sm btn-light" onClick={() => toggleRestoreUsedModal()}>Cancel</button>
                          <button type="button" className="btn w-sm btn-success" id="restore-record" onClick={restoreUsedTag}>Restore</button>
                      </div>
                  </ModalBody>
                  }
                      
                      
                      <ModalBody>
                          <ThreeCircles
                            visible={isRestoringUsed}
                            height="100"
                            width="100"
                            color="#4192C3"
                            ariaLabel="three-circles-loading"
                            wrapperStyle={{style:"margin: 0px auto"}}
                            wrapperClass="justify-content-center"
                            />
                      </ModalBody>
              </Modal>

        </React.Fragment>
    );
};

export default UsedTags;
