const SidebarData = [
    
    {
        label: "My Overview",
        icon: "mdi mdi-view-dashboard",
        url: "/dashboard",
        issubMenubadge: true,
        bgcolor: "bg-primary",
        badgeValue: "Live"
    },
    {
        label: "Dashboard",
        isMainMenu: true,
    },
    {
        label: "Explore Map",
        icon: "mdi mdi-map-search",
        isHasArrow: true,
        url: "/explore-map",
    },
    {
        label: "Catch Ledger",
        icon: "mdi mdi-fish",
        subItem: [
            { sublabel: "All Catches", link: "/all-catches"  },
            { sublabel: "1st Catches", link: "/new-catches" },
            { sublabel: "Re-Catches", link: "/re-catches" },
        ],
    },
    {
        label: "Management",
        isMainMenu: true,
    },
    {
        label: "My Fish Tags",
        icon: "mdi mdi-tag-multiple",
        subItem: [
            { sublabel: "Used Tags", link: "/used-tags" },
            { sublabel: "New  Tags", link: "/new-tags" },
        ],
    },
    {
        label: "My Program",
        icon: "mdi mdi-tag-faces",
        subItem: [
            { sublabel: "Profile", link: "/program-profile" },
            { sublabel: "Settings", link: "/" }
        ],
    },
    // {
    //     label: "Components",
    //     isMainMenu: true,
    // },
    // {
    //     label: "UI Elements",
    //     icon: "mdi mdi-briefcase-variant-outline",
    //     subItem: [
    //         { sublabel: "Alerts", link: "/ui-alerts" },
    //         { sublabel: "Badge", link: "/ui-badge" },
    //         { sublabel: "Breadcrumb", link: "/ui-breadcrumb" },
    //         { sublabel: "Buttons", link: "/ui-buttons" },
    //         { sublabel: "Cards", link: "/ui-cards" },
    //         { sublabel: "Carousel", link: "/ui-carousel" },
    //         { sublabel: "Dropdowns", link: "/ui-dropdowns" },
    //         { sublabel: "Grid", link: "/ui-grid" },
    //         { sublabel: "Images", link: "/ui-images" },
    //         { sublabel: "Lightbox", link: "/ui-lightbox" },
    //         { sublabel: "Modals", link: "/ui-modals" },
    //         { sublabel: "Offcanvas", link: "/ui-offcanvas" },
    //         { sublabel: "Range Slider", link: "/ui-rangeslider" },
    //         { sublabel: "Session Timeout", link: "/ui-sessiontimeout" },
    //         { sublabel: "Pagination", link: "/ui-pagination" },
    //         { sublabel: "Progress Bars", link: "/ui-progressbars" },
    //         { sublabel: "Placeholders", link: "/ui-placeholders" },
    //         { sublabel: "Tabs & Accordions", link: "/ui-tabs-accordions" },
    //         { sublabel: "Typography", link: "/ui-typography" },
    //         { sublabel: "Toasts", link: "/ui-toasts" },
    //         { sublabel: "Video", link: "/ui-video" },
    //         { sublabel: "Popovers & Tooltips", link: "/ui-popovers" },
    //         { sublabel: "Rating", link: "/ui-rating" },
    //     ],
    // },
    // {
    //     label: "Forms",
    //     icon: "ri-eraser-fill",
    //     issubMenubadge: true,
    //     bgcolor: "bg-danger",
    //     badgeValue: "8",
    //     subItem: [
    //         { sublabel: "Form Elements", link: "/form-elements" },
    //         { sublabel: "Form Validation", link: "/form-validation" },
    //         { sublabel: "Form Advanced Plugins", link: "/form-advanced" },
    //         { sublabel: "Form Editor", link: "/form-editor" },
    //         { sublabel: "Form File Upload", link: "/form-uploads" },
    //         { sublabel: "Form X-editable", link: "/form-editors" },
    //         { sublabel: "Form Wizard", link: "/form-wizard" },
    //         { sublabel: "Form Mask", link: "/form-mask" },
    //     ],
    // },
    // {
    //     label: "Tables",
    //     icon: "ri-table-2",
    //     subItem: [
    //         { sublabel: "Basic Tables", link: "/tables-basic" },
    //         { sublabel: "List Js", link: "/tables-listjs" },
    //         { sublabel: "React Datatables", link: "/table-datatables" },
    //     ],
    // },
    // {
    //     label: "Charts",
    //     icon: "ri-bar-chart-line",
    //     subItem: [
    //         { sublabel: "Apex Charts", link: "/chart-apexcharts" },
    //         { sublabel: "Chartjs Charts", link: "/chart-chartjscharts" },
    //         { sublabel: "Re Charts", link: "/chart-floatcharts" },
    //         { sublabel: "Knob Charts", link: "/chart-jknobcharts" },
    //         { sublabel: "Sparkline Charts", link: "/chart-sparklinecharts" },
    //     ],
    // },
    // {
    //     label: "Icons",
    //     icon: "ri-brush-line",
    //     subItem: [
    //         { sublabel: "Box Icons", link: "/icon-boxicon" },
    //         { sublabel: "Material Design", link: "/icons-materialdesign" },
    //         { sublabel: "Dripicons", link: "/icon-dripicons" },
    //         { sublabel: "Font Awesome", link: "/icons-fontawesome" },
    //     ],
    // },
    // {
    //     label: "Maps",
    //     icon: "ri-map-pin-line",
    //     subItem: [
    //         { sublabel: "Google Maps", link: "/maps-google" },
    //         { sublabel: "Vector Maps", link: "/maps-vector" },
    //     ],
    // },
    // {
    //     label: "Multi Level",
    //     icon: "ri-share-line",
    //     subItem: [
    //         { sublabel: "Level 1.1", link: "/#" },
    //         {
    //             sublabel: "Level 1.2", link: "/#",
    //             subMenu: [
    //                 { title: "Level 2.1" },
    //                 { title: "Level 2.2" },
    //             ],
    //         },
    //     ],
    // },
]
export default SidebarData;